<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header class="pb-3">
        <h1>Dépôt massif</h1>
      </header>

      <div>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="submit"
          lazy-validation
        >
          <p v-if="successMessage">
            <v-alert
              text
              dense
              color="teal"
              border="left"
              type="success"
            >
              {{ successMessage }}
            </v-alert>
          </p>

          <p v-if="errors.length">
            <v-alert
              text
              dense
              type="error"
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error.title }}
            </v-alert>
          </p>

          <v-row>
            <v-col
              class="d-flex"
              cols="6"
            >
              <v-text-field
                class="mb-2"
                label="Libellé de la tâche"
                v-model="title"
                required
                :rules="[v => !!v || 'Information est requis']"
              ></v-text-field>
            </v-col>
            <v-col
              class="d-flex"
              cols="6"
            >
              <v-select
                v-model="section"
                :items="sectionList"
                item-text="value"
                item-value="key"
                label="Section"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-file-input
                label="Liste des coffres (csv)"
                v-model="documentCsv"
                messages="Le fichier csv doit contenir une seule colonne (sans en-tête) représentant la liste des matricules des salariés."
                show-size
                :rules="[v => !!v || 'Information est requis']"
                prefix="Parcourir"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                class="mb-2"
                label="Nom du document"
                v-model="documentTitle"
                required
                :rules="[v => !!v || 'Information est requis']"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-file-input
                label="Document à déposer"
                v-model="safeDocument"
                show-size
                :rules="[v => !!v || 'Information est requis']"
                prefix="Parcourir"
              >
              </v-file-input>
            </v-col>
          </v-row>

          <button type="button" :disabled="!valid" class="btn btn-primary float-right mt-4" @click="isConfirmDialogOpen = true">
            <i class="fas fa-check-circle"></i>Valider
          </button>

          <router-link
            :to="{name: 'MassiveUploadList'}"
            class="btn btn-danger float-right mt-4 mr-3 text-white"
          >
            <i class="fa fa-times"></i>
            <span>Annuler</span>
          </router-link>

        </v-form>
      </div>
    </div>
    <MassiveUploadDialog
      v-if="isConfirmDialogOpen !== false"
      :dialog-state="isConfirmDialogOpen"
      :section="section"
      @closeMassiveUploadDialog="isConfirmDialogOpen = false"
      @saveMassiveUpload="submit"
    ></MassiveUploadDialog>
  </div>
</template>

<script>
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'
import Vue from 'vue'
import MassiveUploadDialog from '@/components/Dialog/MassiveUpload/MassiveUploadDialog'

export default {
  name: 'MassiveUpload',
  components: { MassiveUploadDialog },
  data: () => ({
    valid: false,
    successMessage: null,
    errors: [],
    loading: false,
    sectionList: [
      { key: 'individual_com', value: 'Communication individuelle' },
      { key: 'general_com', value: 'Communication générale' }
    ],
    section: null,
    isConfirmDialogOpen: false,
    xTarget: null
  }),
  computed: {
    title: {
      get: function () {
        return this.$store.state.massiveUpload.title
      },
      set: function (value) {
        this.$store.commit('massiveUpload/updateTitle', value)
      }
    },
    safeDocument: {
      get: function () {
        return this.$store.state.massiveUpload.safeDocument
      },
      set: function (value) {
        this.$store.commit('massiveUpload/updateSafeDocument', value)
      }
    },
    documentTitle: {
      get: function () {
        return this.$store.state.massiveUpload.documentTitle
      },
      set: function (value) {
        this.$store.commit('massiveUpload/updateDocumentTitle', value)
      }
    },
    documentCsv: {
      get: function () {
        return this.$store.state.massiveUpload.documentCsv
      },
      set: function (value) {
        this.$store.commit('massiveUpload/updateDocumentCsv', value)
      }
    },
    getSectionName: function () {
      return this.sectionList.filter((e) => {
        return e.id === this.section
      }).map((ee) => {
        return ee.name
      }).join()
    }
  },
  created () {
    this.$emit('update:layout', DefaultLayout)
    document.title = config.title + ' - Dépôt massif'
    this.xTarget = localStorage.getItem('x_target')
  },
  methods: {
    submit: function () {
      this.isConfirmDialogOpen = false

      if (this.$refs.form.validate()) {
        this.valid = true
        this.loading = true
        const data = {
          title: this.title,
          documentNameVault: this.documentTitle,
          section: this.section,
          server: this.xTarget
        }
        const formData = new FormData()
        formData.append('data', JSON.stringify(data))

        let method = 'PUT'
        let slug = '/' + data.slug
        if (this.$route.params.slug === '0') {
          method = 'POST'
          slug = ''
        }

        const options = {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken,
            'Content-Type': 'multipart/form-data',
            'X-Target': this.xTarget
          },
          params: {
            _method: method
          }
        }

        if (this.documentCsv) {
          formData.append('documentcsv', this.documentCsv)
        }
        if (this.safeDocument) {
          formData.append('safeDocument', this.safeDocument)
        }

        Vue.prototype.$http
          .post(config.apiUrl + '/massiveupload' + slug, formData, options)
          .then(response => {
            this.$router.push({ name: 'MassiveUploadList' })
            this.loading = false
          })
          .catch(e => {
            const response = e.response
            this.loading = false

            if (response.status === 400) {
              const data = response.data
              this.errors = data.violations
              this.successMessage = null
            }

            if (response.status === 403) {
              this.$store.dispatch('logout', 'rejected')
            }
          })
      }
    },
    checkChar: function (e) {
      this.slug = e.replace(/[^a-zA-Z0-9]/g, '-')
    }
  }
}
</script>
