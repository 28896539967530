<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialogState"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Validation des informations</span>
        </v-card-title>
        <v-container>
          <v-card-text>

              <v-row>
                <v-col cols="12">
                  <strong>Libellé de la tâche: </strong>{{$store.state.massiveUpload.title}}
                </v-col>
                <v-col cols="12">
                  <strong>Section: </strong>{{section}}
                </v-col>
                <v-col cols="12">
                  <strong>Libellé du document dans le coffre: </strong>{{$store.state.massiveUpload.documentTitle}}
                </v-col>
                <v-col cols="12">
                  <strong>Liste des coffres (csv): </strong>
                  <a :href="getCsvLink" :download="$store.state.massiveUpload.documentCsv.name">
                    {{$store.state.massiveUpload.documentCsv.name}}
                  </a>
                </v-col>
                <v-col cols="12">
                  <strong>Document à déposer: </strong>
                  <a :href="getDocumentLink" :download="$store.state.massiveUpload.safeDocument.name">
                    {{$store.state.massiveUpload.safeDocument.name}}
                  </a>
                </v-col>
              </v-row>

          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="$emit('closeMassiveUploadDialog')"
          >
            Annuler
          </v-btn>

          <button type="button" @click="$emit('saveMassiveUpload')" class="v-btn v-btn--flat v-btn--text theme--light v-size--default blue--text text--darken-1">
            Valider
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'MassiveUploadDialog',
  props: {
    dialogState: Boolean,
    section: String
  },
  computed: {
    getCsvLink () {
      return URL.createObjectURL(this.$store.state.massiveUpload.documentCsv)
    },
    getDocumentLink () {
      return URL.createObjectURL(this.$store.state.massiveUpload.safeDocument)
    }
  }
}
</script>
